import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { UserPreferences } from '../../../shared/models/user-preferences.model';
import { CurrencyDecimalPipe } from '../../../shared/pipes/currency-decimal.pipe';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import * as fromPayment from '../../../taxation/store/selectors/payment.selector';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { SubscriptionNextPayment } from '../../models/subscription.model';
import { User, UserAddon, UserPlan } from '../../models/user.model';

@Component({
  selector: `app-profile-header`,
  standalone: true,
  imports: [
    CommonModule,
    MatChipsModule,
    RouterModule,
    TranslateModule,
    MatDividerModule,
    MatTooltipModule,
    CurrencyDecimalPipe,
  ],
  templateUrl: `./profile-header.component.html`,
  styleUrl: `./profile-header.component.scss`,
})
export class ProfileHeaderComponent implements OnInit, OnDestroy {
  currentPath = ``;
  user: User;
  userPlan: UserPlan;
  currentPlanTooltip = ``;
  userPreferences: UserPreferences;
  stablecoins: string[];
  fiats: string[];

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly paymentStore$: Store<fromPayment.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly authStore$: Store<fromAuth.State>,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentPath = this.router.url;

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event: any) => event instanceof NavigationEnd),
        map((event: any) => {
          this.currentPath = event.url;
        })
      )
      .subscribe();

    combineLatest([
      this.authStore$.select(fromAuth.selectUser),
      this.paymentStore$.select(fromPayment.selectSubscriptionNextPayment),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([user, subscriptionNextPayment]: [User, SubscriptionNextPayment]) => {
          this.user = user;

          if (this.user && subscriptionNextPayment) {
            this.userPlan = subscriptionNextPayment.plans.find(
              (sub: UserPlan | UserAddon) => sub !== `AOCARE001` && sub !== `AOCARE002`
            ) as UserPlan;
            this.userPlan = this.userPlan === null || this.userPlan === undefined ? this.user.plan : this.userPlan;
            this.currentPlanTooltip = this.translateService.instant(`CURRENT_PLAN`, {
              plan: this.translateService.instant(`PLANS.${this.userPlan}`),
            });
          }
        })
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectUserPreferences),
        map((userPreferences: UserPreferences) => {
          this.userPreferences = userPreferences;
        })
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectStableCoins),
        map((stablecoins: string[]) => {
          this.stablecoins = stablecoins;
        })
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectFiats),
        map((fiats: string[]) => {
          this.fiats = fiats;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
