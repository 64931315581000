/* eslint-disable no-unused-vars */
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { setUserAction } from '../../../authentication/store/actions/authentication.action';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { loadLiveBalancesAction } from '../../../portfolio-manager/store/actions/insight.action';
import { AccountListResponse } from '../../../taxation/models/account.model';
import { GeolocationDetails } from '../../../taxation/models/geolocation-details.model';
import { User, UserExistence } from '../../../taxation/models/user.model';
import { TransactionService } from '../../../taxation/services/transaction.service';
import { DEFAULT_CONFIGURATION } from '../../constants/configuration.constant';
import { Configuration } from '../../models/configuration.model';
import { CustomError } from '../../models/error.model';
import { IzanamiResponse } from '../../models/feature.model';
import { ReportedToken } from '../../models/reported-token.model';
import { Scam } from '../../models/scam.model';
import { PageTag } from '../../models/tag.model';
import { TransferRequest } from '../../models/transfer-request.model';
import { UserPreferences } from '../../models/user-preferences.model';
import { AccountService } from '../../services/account.service';
import { AppService } from '../../services/app.service';
import { ConfigurationService } from '../../services/configuration.service';
import { FeatureService } from '../../services/feature.service';
import { IntercomService } from '../../services/intercom.service';
import { OnboardingService } from '../../services/onboarding.service';
import { ReportedTokenService } from '../../services/reported-token.service';
import { ScamService } from '../../services/scam.service';
import { ToastService } from '../../services/toast.service';
import { TrackingService } from '../../services/tracking.service';
import { UserService } from '../../services/user.service';
import { UtilsService } from '../../services/utils.service';
import {
  addUserReportedTokenAction,
  addUserScamAction,
  approveEmailTransferAction,
  cancelEmailTransferAction,
  checkUserExistenceAction,
  completeOnboardingAction,
  deleteUserReportedTokenAction,
  deleteUserScamAction,
  getActiveEmailTransferAction,
  goToAction,
  loadAccountsAction,
  loadBelgianFiscalProfilesAction,
  loadCoinsAction,
  loadConfigurationAction,
  loadFeaturesAction,
  loadFiatsAction,
  loadFrenchPostCodesAction,
  loadTrackersAction,
  loadSpanishAutonomousCommunitiesAction,
  loadStablecoinsAction,
  loadUserLocationAction,
  loadUserPreferencesAction,
  loadUserReportedTokenListAction,
  loadUserScamListAction,
  pingOutUserAction,
  pingUserAction,
  pushTagAction,
  setAccountsAction,
  setAnalysisCountAction,
  setBelgianFiscalProfilesAction,
  setCoinsAction,
  setConfigurationAction,
  setEmailTransferRequestAction,
  setFeaturesAction,
  setFiatsAction,
  setFrenchPostCodesAction,
  setGDPRConsentAction,
  setHasEmailTransferRequestAction,
  setOnboardingCompletedAction,
  setOnboardingErrorAction,
  setPartnerAction,
  setSpanishAutonomousCommunitiesAction,
  setStablecoinsAction,
  setUserAcquisitionChannelAction,
  setUserLocationAction,
  setUserPreferencesAction,
  setUserReportedTokenListAction,
  setUserScamListAction,
  startEmailTransferAction,
  trackEventAction,
  updateConfigurationAction,
  updateUserPreferencesAction,
} from '../actions/shared.action';
import { selectQueryParams } from '../selectors/router.selector';
import * as fromShared from '../selectors/shared.selector';
import { GenericResponse } from '../../models/generic-response.model';

@Injectable()
export class SharedEffects {
  loadAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadAccountsAction>>(loadAccountsAction),
      switchMap(() =>
        this.accountService.getAccounts().pipe(map((accounts: AccountListResponse) => setAccountsAction({ accounts })))
      )
    )
  );

  loadCoins$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadCoinsAction>>(loadCoinsAction),
      switchMap(() => this.transactionService.getCoinsWithNames()),
      map((coins: Map<string, string>) => setCoinsAction({ coins }))
    )
  );

  loadConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadConfigurationAction>>(loadConfigurationAction),
      withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
      switchMap(([action, user]: [ReturnType<typeof loadConfigurationAction>, User]) =>
        this.configurationService.getConfiguration(user?.id).pipe(
          map((configuration: Configuration) => {
            const config = configuration ?? DEFAULT_CONFIGURATION;

            if (config.pagination.pageSort === `date`) {
              config.pagination.pageSort = `transactionDate`;
            }

            if (!config.pagination.pageDirection) {
              config.pagination.pageDirection = `desc`;
            }

            return setConfigurationAction({ configuration: config });
          })
        )
      )
    )
  );

  updateConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateConfigurationAction>>(updateConfigurationAction),
      withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
      switchMap(([action, user]: [ReturnType<typeof updateConfigurationAction>, User]) =>
        this.configurationService
          .updateConfiguration(user?.id, action.configuration)
          .pipe(map(() => setConfigurationAction({ configuration: action.configuration })))
      )
    )
  );

  setAnalysisCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setAnalysisCountAction>>(setAnalysisCountAction),
      map((action: ReturnType<typeof setAnalysisCountAction>) => {
        this.intercomService.updateAnalysisCount(action.analysisCount);

        if (action.analysisCount >= 1) {
          return setGDPRConsentAction({ gdprConsent: true });
        } else {
          return setGDPRConsentAction({ gdprConsent: false });
        }
      })
    )
  );

  loadPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserPreferencesAction>>(loadUserPreferencesAction),
      switchMap(() =>
        this.userService
          .getUserPreferences()
          .pipe(map((userPreferences: UserPreferences) => setUserPreferencesAction({ userPreferences })))
      )
    )
  );

  setUserPreference$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof setUserPreferencesAction>>(setUserPreferencesAction),
        tap((action: ReturnType<typeof setUserPreferencesAction>) => {
          this.utilsService.switchLanguage(action.userPreferences.language);
        })
      ),
    { dispatch: false }
  );

  updatePreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof updateUserPreferencesAction>>(updateUserPreferencesAction),
      switchMap((action: ReturnType<typeof updateUserPreferencesAction>) =>
        this.userService.updateUserPreferences(action.userPreferences).pipe(
          map((userPreferences: UserPreferences) => {
            this.utilsService.switchLanguage(action.userPreferences.language);

            if (action.showToast) {
              const translation = this.translateService.instant(`PREFERENCES_UPDATED`);
              this.toastService.success(translation);
            }

            return setUserPreferencesAction({ userPreferences });
          }),
          catchError((error: CustomError) => {
            const errorMessage: string =
              this.translateService.instant(error.errorCode) === error.errorCode
                ? error.message
                : this.translateService.instant(error.errorCode);

            this.toastService.error(errorMessage);

            return EMPTY;
          })
        )
      )
    )
  );

  loadFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadFeaturesAction>>(loadFeaturesAction),
      switchMap((action: ReturnType<typeof loadFeaturesAction>) => this.featureService.getFeatures()),
      map((izanamiResponse: IzanamiResponse) => setFeaturesAction({ features: izanamiResponse.results }))
    )
  );

  loadStableCoins$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadStablecoinsAction>>(loadStablecoinsAction),
      switchMap((action: ReturnType<typeof loadStablecoinsAction>) => this.appService.getStableCoins()),
      map((stablecoins: string[]) => setStablecoinsAction({ stablecoins }))
    )
  );

  loadFiats$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadFiatsAction>>(loadFiatsAction),
      switchMap((action: ReturnType<typeof loadFiatsAction>) => this.appService.getFiats()),
      map((fiats: string[]) => setFiatsAction({ fiats }))
    )
  );

  pingUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof pingUserAction>>(pingUserAction),
        switchMap((action: ReturnType<typeof pingUserAction>) => this.userService.pingUser(action.mixTrackingId))
      ),
    { dispatch: false }
  );

  pingOutUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof pingOutUserAction>>(pingOutUserAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectUser))),
        switchMap(([action, user]: [ReturnType<typeof pingOutUserAction>, User]) => {
          const data = {
            userId: user.email,
          };

          const blob: Blob = new Blob([JSON.stringify(data)], {
            type: `application/json`,
          });
          navigator.sendBeacon(`${environment.apiUrl}/v1/tax/user/ping-out`, blob);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  startEmailTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof startEmailTransferAction>>(startEmailTransferAction),
      switchMap((action: ReturnType<typeof startEmailTransferAction>) =>
        this.userService.startEmailTransfer(action.recipient).pipe(
          map((transferRequest: TransferRequest) => {
            this.router.navigateByUrl(``);
            const translation = this.translateService.instant(`TRANSFER_EMAIL_SENT`);
            this.toastService.success(`${translation} ${transferRequest.recipient}`);

            return setEmailTransferRequestAction({
              emailTransferRequest: transferRequest,
            });
          }),
          catchError((error: Error) => EMPTY)
        )
      )
    )
  );

  getActiveEmailTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof getActiveEmailTransferAction>>(getActiveEmailTransferAction),
      switchMap((action: ReturnType<typeof getActiveEmailTransferAction>) =>
        this.userService.getActiveEmailTransfer().pipe(
          map((transferRequest: TransferRequest) =>
            setEmailTransferRequestAction({
              emailTransferRequest: transferRequest,
            })
          )
        )
      )
    )
  );

  approveEmailTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof approveEmailTransferAction>>(approveEmailTransferAction),
      withLatestFrom(this.store$.select(selectQueryParams)),
      switchMap(([action, params]: [ReturnType<typeof approveEmailTransferAction>, Params]) => {
        if (!params[`id`] || !params[`code`]) {
          this.router.navigateByUrl(``);
          return EMPTY;
        } else {
          return this.userService.approveEmailTransfer(params[`id`], params[`code`]).pipe(
            map((transferRequest: TransferRequest) =>
              setEmailTransferRequestAction({
                emailTransferRequest: transferRequest,
              })
            ),
            catchError((error: CustomError) => {
              const errorMessage: string =
                this.translateService.instant(error.errorCode) === error.errorCode
                  ? error.message
                  : this.translateService.instant(error.errorCode);

              this.toastService.error(errorMessage);

              this.router.navigateByUrl(``);
              return [
                setHasEmailTransferRequestAction({
                  hasEmailTransferError: true,
                }),
              ];
            })
          );
        }
      })
    )
  );

  cancelEmailTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof cancelEmailTransferAction>>(cancelEmailTransferAction),
      switchMap((action: ReturnType<typeof cancelEmailTransferAction>) =>
        this.userService.cancelEmailTransfer(action.requestId).pipe(
          map((transferRequest: TransferRequest) => {
            return setEmailTransferRequestAction({
              emailTransferRequest: transferRequest,
            });
          }),
          catchError((error: Error) => EMPTY)
        )
      )
    )
  );

  goTo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof goToAction>>(goToAction),
        map((action: ReturnType<typeof goToAction>) => {
          this.router
            .navigate([action.url], {
              queryParams: action.queryParams,
            })
            .then(() => {
              if (action.reloadPage) {
                location.reload();
              }
            });
        })
      ),
    { dispatch: false }
  );

  completeOnboarding$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof completeOnboardingAction>>(completeOnboardingAction),
      switchMap((action: ReturnType<typeof completeOnboardingAction>) =>
        this.onboardingService.completeOnboarding(action.affiliateId, action.user, action.userPreferences).pipe(
          switchMap((user: User) => {
            return [setOnboardingCompletedAction()];
          }),
          catchError((onboardingError: Error) => {
            return [setOnboardingErrorAction({ onboardingError })];
          })
        )
      )
    )
  );

  setOnboardingCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setOnboardingCompletedAction>>(setOnboardingCompletedAction),
      map((action: ReturnType<typeof setOnboardingCompletedAction>) => {
        const authCallbackUrl = localStorage.getItem(`auth_callback_url`);
        localStorage.removeItem(`auth_callback_url`);
        if (authCallbackUrl) {
          const { url, queryParams } = this.utilsService.serializeRoute(authCallbackUrl);
          return goToAction({ url, queryParams });
        } else {
          return goToAction({ url: `accounts`, reloadPage: true });
        }
      })
    )
  );

  loadUserLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserLocationAction>>(loadUserLocationAction),
      switchMap((action: ReturnType<typeof loadUserLocationAction>) =>
        this.userService.getUserLocation().pipe(
          map((userLocation: GeolocationDetails) => {
            if (userLocation.languages) {
              const lang = userLocation.languages.substring(0, 2);
              this.utilsService.switchLanguage(lang);
            }

            return setUserLocationAction({ userLocation });
          }),
          catchError((error: Error) => {
            this.toastService.error(error.message);
            return EMPTY;
          })
        )
      )
    )
  );

  loadFrenchPostCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadFrenchPostCodesAction>>(loadFrenchPostCodesAction),
      switchMap((action: ReturnType<typeof loadFrenchPostCodesAction>) =>
        this.appService
          .getFrenchPostCodes()
          .pipe(map((frenchPostCodes: Map<string, string>) => setFrenchPostCodesAction({ frenchPostCodes })))
      )
    )
  );

  loadBelgianFiscalProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadBelgianFiscalProfilesAction>>(loadBelgianFiscalProfilesAction),
      switchMap((action: ReturnType<typeof loadBelgianFiscalProfilesAction>) =>
        this.onboardingService
          .getBelgianFiscalProfiles()
          .pipe(
            map((belgianFiscalProfiles: Map<string, string>) =>
              setBelgianFiscalProfilesAction({ belgianFiscalProfiles })
            )
          )
      )
    )
  );

  loadSpanishAutonomousCommunities$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadSpanishAutonomousCommunitiesAction>>(loadSpanishAutonomousCommunitiesAction),
      switchMap((action: ReturnType<typeof loadSpanishAutonomousCommunitiesAction>) =>
        this.appService.getSpanishAutonomousCommunities().pipe(
          map((spanishAutonomousCommunities: Map<string, string>) =>
            setSpanishAutonomousCommunitiesAction({
              spanishAutonomousCommunities,
            })
          )
        )
      )
    )
  );

  checkUserExistence$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof checkUserExistenceAction>>(checkUserExistenceAction),
        withLatestFrom(this.authStore$.pipe(select(fromAuth.selectAuthUser))),
        switchMap(([action, user]: [ReturnType<typeof checkUserExistenceAction>, User]) =>
          this.userService.checkUserExistence(action.email).pipe(
            map((userExistence: UserExistence) => {
              this.utilsService.clearBrowserStorages();
              this.utilsService.clearPWACache();

              if (!action.email) {
                sessionStorage.setItem(`waltio_user`, user.email);
              } else if (userExistence.exists) {
                sessionStorage.setItem(`waltio_user`, action.email);
              }

              window.location.reload();
            }),
            catchError((error: CustomError) => {
              const message = error.errorCode ?? error.message;

              this.toastService.error(message);

              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  loadGTM$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof loadTrackersAction>>(loadTrackersAction),
        map(() => {
          this.trackingService.loadTrackers();
        })
      ),
    { dispatch: false }
  );

  pushTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof pushTagAction>>(pushTagAction),
      withLatestFrom(
        this.authStore$.pipe(select(fromAuth.selectUser)),
        this.sharedStore$.pipe(select(fromShared.selectIsTrackingLoaded))
      ),
      switchMap(([action, user, isTrackingLoaded]: [ReturnType<typeof pushTagAction>, User, boolean]) => {
        const tag: PageTag = {
          event: `page_view`,
          environment_name: environment.production ? `production` : `dev`,
          environment_market: user?.fiscalResidency,
          user_login_status: user ? `logged in` : `logged out`,
          user_type: user?.plan === `PLN000` ? `registered` : `customer`,
        };

        this.trackingService.pushTag(tag);

        if (!isTrackingLoaded && user) {
          return [pingUserAction({ mixTrackingId: user.id })];
        } else {
          return EMPTY;
        }
      })
    )
  );

  trackEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof trackEventAction>>(trackEventAction),
        switchMap((action: ReturnType<typeof trackEventAction>) =>
          this.trackingService.trackEvent(action.event, action.data).pipe(
            map((res: GenericResponse) => {
              return EMPTY;
            }),
            catchError((error: CustomError) => {
              const message = error.errorCode ?? error.message;

              this.toastService.error(message);

              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  setUserAcquisitionChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setUserAcquisitionChannelAction>>(setUserAcquisitionChannelAction),
      switchMap((action: ReturnType<typeof setUserAcquisitionChannelAction>) =>
        this.userService
          .setUserAcquisitionChannel(action.acquisitionChannel)
          .pipe(map((user: User) => setUserAction({ user })))
      )
    )
  );

  setPartner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof setPartnerAction>>(setPartnerAction),
        tap((action: ReturnType<typeof setPartnerAction>) => {
          if (action.partner) {
            sessionStorage.setItem(`partner`, action.partner);
          } else {
            sessionStorage.removeItem(`partner`);
          }
        })
      ),
    { dispatch: false }
  );

  loadUserScamList$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserScamListAction>>(loadUserScamListAction),
      switchMap((action: ReturnType<typeof loadUserScamListAction>) =>
        this.scamService.getUserScamList().pipe(map((userScamList: Scam[]) => setUserScamListAction({ userScamList })))
      )
    )
  );

  addUserScam$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof addUserScamAction>>(addUserScamAction),
      switchMap((action: ReturnType<typeof addUserScamAction>) =>
        this.scamService.addUserScam(action.scam).pipe(
          switchMap((userScamList: Scam[]) => {
            const message = this.translateService.instant(`SCAM_ADDED`);
            this.toastService.success(message);

            return [loadLiveBalancesAction(), setUserScamListAction({ userScamList })];
          })
        )
      )
    )
  );

  deleteUserScam$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof deleteUserScamAction>>(deleteUserScamAction),
      switchMap((action: ReturnType<typeof deleteUserScamAction>) =>
        this.scamService.deleteUserScam(action.scam).pipe(
          switchMap((userScamList: Scam[]) => {
            const message = this.translateService.instant(`SCAM_DELETED`);
            this.toastService.success(message);

            return [loadLiveBalancesAction(), setUserScamListAction({ userScamList })];
          })
        )
      )
    )
  );

  loadUserReportedTokenList$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof loadUserReportedTokenListAction>>(loadUserReportedTokenListAction),
      switchMap((action: ReturnType<typeof loadUserReportedTokenListAction>) =>
        this.reportedTokenService
          .getUserReportedTokenList()
          .pipe(
            map((userReportedTokenList: ReportedToken[]) => setUserReportedTokenListAction({ userReportedTokenList }))
          )
      )
    )
  );

  addUserReportedToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof addUserReportedTokenAction>>(addUserReportedTokenAction),
      switchMap((action: ReturnType<typeof addUserReportedTokenAction>) =>
        this.reportedTokenService.addUserReportedToken(action.reportedToken).pipe(
          switchMap((userReportedTokenList: ReportedToken[]) => {
            const message = this.translateService.instant(`REPORTED_TOKEN_ADDED`);
            this.toastService.success(message);

            return [loadLiveBalancesAction(), setUserReportedTokenListAction({ userReportedTokenList })];
          })
        )
      )
    )
  );

  deleteUserReportedToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof deleteUserReportedTokenAction>>(deleteUserReportedTokenAction),
      switchMap((action: ReturnType<typeof deleteUserReportedTokenAction>) =>
        this.reportedTokenService.deleteUserReportedToken(action.reportedToken).pipe(
          switchMap((userReportedTokenList: ReportedToken[]) => {
            const message = this.translateService.instant(`REPORTED_TOKEN_DELETED`);
            this.toastService.success(message);

            return [loadLiveBalancesAction(), setUserReportedTokenListAction({ userReportedTokenList })];
          })
        )
      )
    )
  );

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly utilsService: UtilsService,
    private readonly appService: AppService,
    private readonly accountService: AccountService,
    private readonly transactionService: TransactionService,
    private readonly configurationService: ConfigurationService,
    private readonly featureService: FeatureService,
    private readonly translateService: TranslateService,
    private readonly onboardingService: OnboardingService,
    private readonly userService: UserService,
    private readonly trackingService: TrackingService,
    private readonly intercomService: IntercomService,
    private readonly scamService: ScamService,
    private readonly reportedTokenService: ReportedTokenService,
    private readonly authStore$: Store<fromAuth.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly router: Router
  ) {}
}
