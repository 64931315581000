import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountListResponse } from '../../taxation/models/account.model';
import { environment } from '../../../environments/environment';
import { UserAccount } from '../../taxation/models/user-account.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: `root`,
})
export class AccountService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Get list of all supported accounts
   *
   * @returns accounts list response
   */
  getAccounts(): Observable<AccountListResponse> {
    return this.http.get<AccountListResponse>(`${environment.apiUrl}/v1/tax/account/list`);
  }

  getUserAccounts(): Observable<UserAccount[]> {
    return this.http.get<UserAccount[]>(`${environment.apiUrl}/v1/tax/account/user/list`).pipe(
      map((userAccounts: UserAccount[]) => {
        userAccounts.sort((a: UserAccount, b: UserAccount) => {
          if (a.type === `HIDDEN` && b.type !== `HIDDEN`) {
            return 1;
          } else if (a.type !== `HIDDEN` && b.type === `HIDDEN`) {
            return -1;
          } else {
            return 0;
          }
        });

        const filteredAccounts = userAccounts.filter(
          (account: UserAccount) => !(account.type === `HIDDEN` && account.nbOfTransactions === 0)
        );

        return filteredAccounts;
      })
    );
  }

  getUserAccount(accountId: string): Observable<UserAccount> {
    return this.http.get<UserAccount>(`${environment.apiUrl}/v1/tax/account/byId/${accountId}`);
  }
}
