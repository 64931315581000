import { Action, createReducer, on } from '@ngrx/store';
import * as OpportunityActions from '../actions/opportunity.action';
import { PlatformFee } from '../../models/user-fees.model';
import { Yield } from '../../models/yield-opportunity.model';

export interface State {
  portfolioValue: number;
  missedPassiveYield: number;
  bestAnnualFees: number;
  cheapestPlatforms: PlatformFee[];
  missingOpportunity: number;
  availableYields: Yield[];
}

export const initialState: State = {
  portfolioValue: 1, // Avoid division by zero
  missedPassiveYield: null,
  bestAnnualFees: null,
  cheapestPlatforms: null,
  missingOpportunity: null,
  availableYields: null,
};

const reducer = createReducer(
  initialState,
  on(OpportunityActions.setPortfolioValueAction, (state: State, { portfolioValue }: any) => ({
    ...state,
    portfolioValue,
  })),
  on(OpportunityActions.setAvailableYieldsAction, (state: State, { availableYields }: any) => ({
    ...state,
    availableYields,
  })),
  on(OpportunityActions.setMissedPassiveYieldAction, (state: State, { missedPassiveYield }: any) => ({
    ...state,
    missedPassiveYield,
  })),
  on(OpportunityActions.setCheapestPlatformsAction, (state: State, { cheapestPlatforms }: any) => ({
    ...state,
    cheapestPlatforms,
  })),
  on(OpportunityActions.setBestAnnualFeesAction, (state: State, { bestAnnualFees }: any) => ({
    ...state,
    bestAnnualFees,
  })),
  on(OpportunityActions.setMissingOpportunityAction, (state: State, { missingOpportunity }: any) => ({
    ...state,
    missingOpportunity,
  }))
);

export const opportunityReducer = (state: State | undefined, action: Action): State => reducer(state, action);
